body {
  font-size: 14px;
  /* font-family: "Montserrat"; */
}
.apexcharts-legend-text {
  text-transform: capitalize;
}

.left-panel {
  width: 100%;
  background: url(../images/left_panel_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 12px 0;
  padding: 0 0 30px 0;
  height: 100%;
}

.col-md-4.left-panel-box {
  position: fixed;
}
.main-request {
  position: relative;
}
.logo-request {
  width: 100px;
}

.logo-panel {
  padding: 30px 20px 20px 20px;
  text-align: left;
}
.left-panel h3 {
  text-align: left;
  color: #fff;
  padding-left: 20px;
  font-size: 32px;
  margin-top: 20px;
  font-weight: 600;
  font-family: Montserrat;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  counter-reset: step;
  margin-left: 0;
}
#progressbar li {
  list-style-type: none;
  color: #5e99b7;
  text-transform: capitalize;
  width: 100%;
  float: none;
  position: relative;
  text-align: left;
  font-size: 16px;
  margin-bottom: 20px;
  height: 58px;
  line-height: 22px;
}
#progressbar li.active {
  color: #fff;
}
#progressbar li.active:before,
#progressbar li.active:pla::placeholder {
  background: #fff;
  color: white;
}
#progressbar li:before {
  content: "";
  width: 20px;
  display: inline-block;
  font-size: 10px;
  height: 20px;
  left: 0;
  background: #015a84;
  border: 1px #81878a solid;
  border-radius: 100%;
  position: relative;
  margin: 0px 10px 0px 0;
  text-align: center;
  z-index: 99;
  top: 4px;
}

#progressbar li span {
  width: 8px;
  height: 8px;
  background: #5e99b7;
  color: #5e99b7;
  position: absolute;
  left: 6px;
  border-radius: 100%;
  top: 10px;
  z-index: 99;
}

#progressbar li.active:before {
  background: #ffffff;
  border: 1px #ffffff solid;
}
#progressbar li.active span {
  width: 8px;
  height: 8px;
  background: #006090;
  color: #006090;
  position: absolute;
  left: 6px;
  border-radius: 100%;
  top: 10px;
  z-index: 99;
}
#progressbar li:after {
  content: "";
  width: 1px;
  height: 62px;
  background: #709db3;
  position: absolute;
  left: 10px;
  top: 20px;
  z-index: 1;
}

strong.tab-content {
  font-weight: normal;
}
#progressbar li:last-child:after {
  display: none;
}

.left-panel h4 {
  color: #fff;
  text-align: left;
  padding-left: 20px;
  padding-top: 20px;
}
.left-panel p {
  color: #fff;
  font-size: 14px;
  text-align: left;
  padding: 0 20px 20px 20px;
}
ul#progressbar {
  padding-left: 20px;
}

.email-id-col {
  width: 100%;
  bottom: 0px;
  position: relative;
  display: block;
  text-align: right;
  padding-right: 20px;
  padding-top: 70px;
}
.email-id-col a {
  color: #fff;
}
.right-panel {
  width: 60%;
  padding: 20px 20px;
  position: relative;
  float: left;
}
.col-md-8.request-right {
  position: absolute;
  right: 0px;
}
.right-panel h2 {
  text-align: left;
  font-size: 32px;
}
.right-panel h5 {
  text-align: left;
  font-size: 16px;
  margin-bottom: 40px;
}

.right-panel .form-group label {
  text-align: left;
  width: 100%;
  color: #000;
  font-weight: 500;
}

button.btn.btn-primary.next-btn {
  float: right;
  margin: 30px 0;
  position: relative;
  background: #006090;
  border: 0px;
  padding: 5px 20px;
  height: 34px;
}
button.btn.btn-primary.next-btn:hover {
  background: #06415d;
}

.divider {
  background: #e2e2e2;
  height: 1px;
  width: 80%;
  clear: both;
  margin: 0 auto;
}

.loginnow-btn {
  margin-top: 20px;
}

.loginnow-btn a {
  text-decoration: underline;
}

.copyright {
  width: auto;
  float: left;
  text-align: right;
  position: absolute;
  right: 30px;
  bottom: 40px;
}

ul.steps-right {
  width: 20px;
  position: absolute;
  float: right;
  margin-right: 30px;
  right: 0;
  top: 35%;
}

ul.steps-right li {
  list-style: none;
  width: 7px;
  height: 30px;
  background: #e2e2e2;
  border-radius: 10px;
  margin: 10px 0px 10px 0px;
}
ul.steps-right li.active {
  background: #006090;
}
button.btn.btn-primary.next-btn i.fa.fa-chevron-right {
  font-size: 12px;
}

button.btn.btn-primary.back-btn i.fa.fa-chevron-left {
  font-size: 12px;
}
.form-group.monthly-card-payment input.form-control {
  position: relative;
}

button.btn.btn-primary.back-btn {
  background: #e2e2e2;
  border: 0px;
  color: #000;
  float: left;
  margin-top: 30px;
  padding: 5px 20px;
  height: 34px;
}

button.btn.btn-primary.back-btn:hover {
  background: #ccc;
}
span.dollor-col {
  position: absolute;
  margin-top: 30px;
  margin-left: 16px;
  left: 0px;
  border-radius: 5px 0px 0px 5px;
  width: 48px;
  background: #e2e2e2;
  height: 43px;
  text-align: center;
  padding-top: 8px;
  /* z-index: 9; */
}
input.form-control {
  height: 45px !important;
}
select.form-control {
  height: 42px !important;
}

.form-group.amount-required input.form-control {
  padding-left: 52px;
}

.form-group.monthly-card-payment input.form-control {
  padding-left: 52px;
}

.form-check.form-switch input[type="checkbox"] {
  height: 0;
  width: 0;
  font-size: 0;
  visibility: hidden;
  display: none;
}
.form-check.form-switch .yes-checkbox label {
  cursor: pointer;
  text-indent: 8px;
  width: 60px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  color: #fff;
}
.form-check.form-switch .no-checkbox label {
  cursor: pointer;
  text-indent: 30px;
  width: 60px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  color: #fff;
}

.form-check.form-switch label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.form-check.form-switch input:checked + label {
  background: #006090;
}

.form-check.form-switch input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.form-check.form-switch label:active:after {
  width: 30px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 0;
}
.yes-checkbox {
  width: 80px;
  float: left;
  margin-bottom: 10px;
  margin-right: 20px;
  position: relative;
}

.no-checkbox {
  width: 80px;
  float: left;
  margin-bottom: 10px;
}
.Term-found input.form-control {
  padding-left: 52px;
}

.confirmation-screen {
  background: #d1e7dd;
  padding: 12px;
  border-radius: 10px;
  text-align: left;
}
.confirmation-screen h3 {
  font-size: 20px;
  color: #0f5132;
  margin-bottom: 20px;
}

.confirmation-screen h3 i.fa.fa-check-circle {
  font-size: 26px;
  margin: 0px 5px 0 0;
}

.note {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

button.btn.btn-primary.confirm-btn {
  background: #006090;
  border: 0px;
  text-transform: capitalize;
  padding: 10px 30px;
}

div#confirmationModalCenter button.close {
  position: absolute;
  right: 5px;
  top: 0;
  color: red;
  z-index: 9;
}

img.icon-div {
  position: absolute;
  width: 50px;
  margin: 0 auto;
  display: block;
  left: 50%;
  transform: translate(-50%);
  top: -24px;
}

h5#confirmationModalCenterTitle {
  margin-top: 20px;
}
.App.login-page {
  background: #f6f2ea;
  width: 100%;
  min-height: 100vh;
}

.login-screen {
  width: 100%;
  max-width: 400px !important;
  margin: 40px auto !important;
  height: 100vh;
}

.login-box {
  width: 100%;
  background: #fff;
  padding: 20px;
  text-align: left;
  box-shadow: 0 1px 1px rgb(0 0 0 / 1%), 0 2px 10px rgb(0 0 0 / 23%);
  border-radius: 20px;
  margin: 12px 0px;
  border: 10px #f6f5f1 solid;
}

.login-box h3 {
  font-size: 20px;
  font-weight: bold;
}

.login-box h5 {
  font-size: 14px;
  margin-bottom: 30px;
}

.remember-div {
  width: auto;
  float: left;
}
button.btn.btn-primary.login-btn {
  width: 100%;
  display: block;
  background: #006090;
  border: 0px;
  margin-bottom: 30px;
}

.forgot-div {
  float: right;
}

button.btn.btn-primary.login-btn:hover {
  background: #06415d;
}
button.btn.btn-primary.login-btn i.fa.fa-chevron-right {
  font-size: 12px;
}

.form-group.loginnow-btn {
  text-align: center;
}

img.login-logo {
  width: 140px;
  margin: 0 auto;
  display: block;
}

header {
  background: #006090;
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 99;
}
.bg-dark {
  background-color: #006090 !important;
}

img.user-img {
  margin-right: 12px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.nav-item.business-btn {
  padding: 2px 20px;
  background: #02547a;
  border-radius: 5px;
  position: relative;
}

.navbar-collapse {
  justify-content: space-between;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}

.border-divider {
  border-left: 1px #ccc solid;
  /* width: 20px; */
  height: 30px;
  margin-top: 7px;
  /* margin-left: 10px; */
}

li.nav-item.dropdown.float-right {
  position: absolute;
  right: 0;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.verify-panel {
  margin: 0px 0px 20px 0;
  text-align: left;
}

button.close {
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}

.card.dashboard-card {
  padding: 20px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 1%), 0 2px 10px rgb(0 0 0 / 23%);
  border-radius: 6px;
  margin-top: 42px;
  padding-bottom: 70px;
}

.stepswrapperClass {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.titlelabel {
  min-width: 162px;
  font-size: 15px;
}
._2Jtxm._35Ago .titlelabel {
  font-weight: bold !important;
  color: #006090;
}
._2Jtxm._2ZUAI .titlelabel {
  font-weight: bold !important;
  color: #198754;
}

.buttonsdiv a:first-child {
  background: #e2e2e2 !important;
  color: #000 !important;
  font-weight: bold;
  padding: 5px 20px;
  margin-left: 40px;
  font-size: 15px;
  filter: opacity(1);
}
.buttonsdiv a:nth-child(2) {
  background: #006090 !important;
  color: #fff !important;
  font-weight: bold;
  padding: 5px 20px;
  margin-right: 40px;
  font-size: 15px;
  filter: opacity(1);
}

.stepClassdiv._2Jtxm._35Ago ._2kL0S {
  background: #006090;
}
.stepClassdiv ._1hzhf {
  color: #006090;
}

._3uApM.buttonsdiv {
  position: relative;
  margin-top: -70px;
}

.tooltippanel {
  background: #fff !important;
  color: #000 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

button.btn-primary.banking-btn {
  background: #006090;
  border: 0px;
  padding: 10px 30px;
  margin-right: 20px;
  margin: 20px 20px 20px 0px;
  float: left;
  font-size: 15px;
}
button.btn.btn-primary.banking-btn:hover,
.download-btn button:hover,
.download-btn button:active,
.export-btn:hover,
.export-btn:active {
  background: #024f76 !important;
}

.download-btn button,
.show > .btn-primary.dropdown-toggle,
.export-btn {
  background: #024f76;
}
button.btn.btn-primary.accounting-btn {
  background: #c3181e;
  border: 0px;
  padding: 10px 30px;
  margin-right: 20px;
  margin: 20px 0px 20px 0;
  float: left;
  font-size: 15px;
}
button.btn.btn-primary.accounting-btn:hover {
  background: #ab1318;
}

button.btn.btn-primary.accounting-btn i.fa.fa-chevron-right {
  font-size: 12px;
  margin-left: 10px;
}

button.btn.btn-primary.banking-btn i.fa.fa-chevron-right {
  font-size: 12px;
  margin-left: 10px;
}

.download-dropdown .dropdown-item {
  font-size: 14px;
}
/* .download-dropdown .dropdown-menu.show {
  transform: translate3d(0.8px, 30px, 0px) !important;
} */

.export-dropdown {
  max-width: 420px !important;
  width: 420px;
  background: #fff;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 50%);
  padding: 20px;
  border-radius: 10px;
  height: 350px;
  overflow-x: auto;
}

.export-dropdown::-webkit-scrollbar {
  width: 8px;
  background-color: #ccc;
  border-radius: 20px;
}

.export-dropdown::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #999;
  border-radius: 20px;
}

.export-dropdown::-webkit-scrollbar-button {
  display: none;
}

.down-arrow {
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: -12px;
  color: #fff;
}

.download-dropdown .dropdown-item:hover {
  color: #006090;
  font-weight: 600;
}

button.copyicon-col.btn.btn-primary {
  background: #006090;
  border: 0px;
  padding: 10.5px 20px;
  position: absolute;
  right: 0px;
  margin-top: -45px;
  border-radius: 0px 5px 5px 0px;
}
button.checkstatus-btn.btn.btn-primary {
  background: #006090;
  border: 0px;
  padding: 10.5px 20px;
  position: absolute;
  right: 0px;
  margin-top: -45px;
  border-radius: 0px 5px 5px 0px;
}

.banking-url .form-group {
  max-width: 432px;
  position: relative;
}

.upload-doc-panel {
  width: 100%;
  float: left;
  position: relative;
  min-height: 300px;
  padding: 0 20px 20px 20px;
}
.upload-doc-panel.upload-doc:before {
  position: absolute;
  content: "";
  width: 1px;
  background: #ccc;
  height: 350px;
  top: 0;
  left: 0;
}

.upload-doc-panel-merchant::before {
  background: unset;
}

.review-application .order-2 {
  border-left: 1px solid #ccc;
}

.dashboard-card h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
}

label {
  font-size: 14px;
  font-weight: bold;
}
._2Jtxm._2ZUAI ._2JvrO {
  background-color: #198754;
  color: white;
}
.form-control {
  font-size: 14px !important;
}
input.upload-checkbox {
  height: 20px;
  width: 20px;
  margin: 5px;
}
.upload-doc-panel label {
  font-size: 14px !important;
  font-weight: normal !important;
  margin: 0 !important;
  vertical-align: top;
  line-height: 28px;
}
.css-1o2q262-control {
  min-height: 45px !important ;
}

.upload-box {
  background: #f6fafc;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  border: 2px #006090 dashed;
  margin-bottom: 10px;
}

.upload-area p {
  margin: 0px;
}

.upload-area ul {
  padding-left: 25px;
  line-height: 26px;
}

button.btn.btn-primary.upload-btn {
  background: #006090;
  border: 0px;
  font-size: 12px;
  margin-bottom: 12px;
}

.upload-box p {
  font-size: 12px;
}

.director-panel h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
}

.accordion-wrapper {
  margin-top: 0.5em;
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 0.5em 1.5em;
  border: solid 1px #ccc;
  border-radius: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion-title::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
}

.accordion-title:hover,
.accordion-title.open {
  color: black;
}

.accordion-title.open::after {
  content: "";
  border-top: 0;
  border-bottom: 5px solid;
}

.accordion-content {
  padding: 1em 1.5em;
}

input.primary-checkbox {
  width: 20px;
  height: 20px;
  margin: 5px 5px 0px 0px;
  vertical-align: top;
}

label.set-primary {
  line-height: 32px;
  padding-left: 10px;
  vertical-align: top;
}
.accordion-title {
  background: #ececec;
  border: 0px;
  border-radius: 6px;
  line-height: 50px;
}

.accordion-item {
  background: #ececec;
  border: 0px;
  border-radius: 6px;
  /* line-height: 50px; */
  margin: 10px 0px;
}

.form-group.consent-panel label {
  vertical-align: top;
  line-height: 30px;
  padding-left: 5px;
}

.uploaded-file p span {
  color: #006090;
  margin-bottom: 20px;
  display: inline-block;
  text-transform: uppercase;
}
.uploaded-file i.fa.fa-trash {
  float: right;
  font-size: 20px;
  color: red;
}

span.logo-icon {
  background: #005479;
  width: 65px;
  height: 57px;
  display: inline-block;
  left: -15px;
  line-height: 52px;
  position: absolute;
  top: -1px;
  text-align: center;
  position: relative;
}

.navbar-dark .navbar-brand strong {
  padding-left: 50px;
}

.react-tel-input .flag-dropdown {
  background-color: unset !important;
  border-left: unset !important;
  border-top: unset !important;
  border-bottom: unset !important;
}

img.logo-dashboard {
  width: 26px;
}

.tooltip-panel {
  width: auto;
  float: left;
}
.banking-url label {
  display: block;
  width: 100%;
}

.banking-url {
  width: 100%;
  float: left;
}

.tooltip-panel {
  width: auto;
  float: left;
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
}

.accounting-tooltip {
  margin-top: 30px;
}
.sidebar-panel {
  display: flex;
  height: 100%;
  margin: 80px 20px 0 0;
}

.sidebar {
  box-shadow: 0 1px 1px rgb(0 0 0 / 1%), 0 2px 10px rgb(0 0 0 / 23%);
  width: 300px;
  height: 85vh;
  border-radius: 0px 0px 10px 10px;
}

li.sc-ksBlkl.gmgIpm.menu-item {
  text-align: left;
}

.siderbar-btn {
  border: 0px;
  background: #006090;
  color: #fff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: -20px;
  z-index: 9;
  position: absolute;
  bottom: 55px;
}

.sidebar-panel .menu a i.fa {
  font-size: 18px;
  margin-right: 5px;
  width: 22px;
}
.sidebar-inner {
  background: #fff !important;
  border-radius: 0px 0px 10px 10px;
}
.dashboard-panel .navbar-dark .navbar-brand {
  width: 256px;
}

.sc-gswNZR.djZVNf.sidebar.collapsed {
  box-shadow: 0 1px 1px rgb(0 0 0 / 1%), 0 2px 10px rgb(0 0 0 / 23%);
  height: 85vh;
}

.fontawesomeicon::before {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.User-Tie::before {
  font: var(--fa-font-solid);
  content: " \f508";
}

i.fas.fa-user-tie {
  font-size: 18px;
  width: 22px;
  /* margin-right: 5px; */
}

li.sc-ksBlkl.gmgIpm.menu-item {
  text-align: left;
  /* padding: 5px; */
}
.container-fluid.merchant-body {
  display: flex;
}

.right-panel-main h3 i.fa {
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.right-panel-main h3 {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.right-panel-main {
  margin-top: 80px;
  width: 100%;
  overflow: hidden;
}
.right-panel-main .card.dashboard-card {
  margin-top: 25px;
  padding-bottom: 0px;
  margin-bottom: 30px;
  min-height: 510px;
}

p.bottom-text {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
}

.right-panel-main h3 i.fas.fa-user-tie {
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

/* .sidebar-panel li.menu-item:first-child {
  padding: 0px !important;
  line-height: 60px;
  height: 60px;
} */

.sidebar-panel li.menu-item a {
  padding: 0px;
}

.sidebar-panel li.menu-item a span a {
  display: block;
  height: 60px;
  line-height: 60px;
  color: #000;
  padding: 0px 20px !important;
}

.sidebar-panel li.menu-item a span a.active {
  display: block;
  height: 60px;
  line-height: 60px;
  background: #e2eff4;
  color: #006090;
  padding: 0px 20px;
  font-weight: bold;
  border-left: 3px #006090 solid;
}

.sidebar-panel li.menu-item a span a:hover {
  display: block;
  height: 50px;
  line-height: 50px;
  background: #e2eff4;
  color: #006090;
  padding: 0px 20px;
  font-weight: bold;
  text-decoration: none;
}

button.btn.btn-primary.upload-image {
  background: #e2e2e2;
  color: #000;
  border: 0px;
  margin: 20px auto;
  display: block;
  border-radius: 6px;
  height: 42px;
  font-weight: bold;
  padding: 0px 20px;
  font-size: 12px;
}

button.btn.btn-primary.upload-image:hover {
  background: #cfc9c9;
}

input#input-file {
  display: none;
}

.upload-image label {
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-change-color.dashboard-body {
  background: #f6f2ea;
  min-height: 100vh;
}

.no-padding {
  padding: 0px !important;
}

.right-panel-main button.btn.btn-primary.accounting-btn {
  margin-top: 0px;
}

.banking-panel,
.merchent-accounting-panel {
  width: 100%;
  float: left;
}

.download-btn {
  position: absolute;
  right: 0px;
  top: -64px;
}

.financial-service {
  border: 1px #5e99b7 solid;
  border-radius: 5px;
  margin: 10px 0px;
  height: 350px;
  position: relative;
  width: 100%;
}

.financial-service h4 {
  background: #5e99b7;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 15px 0px;
  font-size: 15px;
  margin: 0px;
}

.financial-service .card-1 {
  background: #f3f3f3;
  width: 100%;
  float: left;
  padding: 10px;
  margin-bottom: 1px;
}

.financial-service .card-1 p {
  margin: 0px;
  line-height: 22px;
  font-size: 12px;
}

.amount-para {
  text-align: right;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.box-id-1 {
  /* width: 50%; */
  float: left;
  margin-left: 0;
  margin-top: 5px;
  text-align: right;
}

.box-id-1 p,
.box-id-2 p {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.box-id-2 {
  /* width: 50%; */
  float: right;
  margin-left: 0;
  margin-top: 5px;
}

.box-id-2 p {
  text-align: right;
}
.financial-service .card-2 {
  background: #e8e8e8;
}

.card-bottom {
  background: #cfe1ea;
  width: 100%;
  float: none;
  display: flex;
  border-radius: 5px;
  margin: 0 auto;
  padding: 5px 10px 10px 10px;
  position: absolute;
  bottom: 0;
  height: max-content;
  justify-content: space-between;
  flex-wrap: wrap;
  /* min-height: 100px; */
}

.income-panel {
  border: 1px #006090 solid;
  border-radius: 5px;
  margin: 10px 0px;
}

.income-panel h4 {
  background: #006090;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 15px 0px;
  font-size: 15px;
  margin: 0px;
}

.white-bg {
  background: #fff !important;
}

.card-bottom p {
  margin: 0px;
  font-size: 13px;
}

.financial-service .card-1 p span {
  color: #5e99b7;
}

.scroll-bar-2 {
  overflow-y: auto;
  padding-right: 0px !important;
  height: calc(350px - 48px - 65px);

  background-attachment: scroll, scroll, local, local;
  -webkit-overflow-scrolling: touch;
  -overflow-scrolling: touch;
  /* padding-bottom: 10px; */
}

.events-panel {
  overflow-y: auto;
  padding-right: 0px !important;
  height: calc(350px - 52px);

  background-attachment: scroll, scroll, local, local;
  -webkit-overflow-scrolling: touch;
  -overflow-scrolling: touch;
}
.scroll-bar-2::-webkit-scrollbar,
.events-panel::-webkit-scrollbar {
  width: 8px;
  background-color: #ccc;
  border-radius: 20px;
}

.scroll-bar-2::-webkit-scrollbar-thumb,
.events-panel::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #999;
  border-radius: 20px;
}

.scroll-bar-2::-webkit-scrollbar-button,
.events-panel::-webkit-scrollbar-button {
  display: none;
}

.bottom-2 {
  background: #e2e2e2;
}

.data-panel {
  width: 100%;
  float: left;
  z-index: 0;
  position: relative;
}

.data-panel h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}

button.btn.btn-primary.exportdata-btn.next-btn {
  margin: 32px 40px 0px 0px !important;
  height: 42px;
  float: right;
}

i.fa.fa-chevron-down {
  font-size: 12px;
}

.business-panel label {
  vertical-align: top;
  line-height: 30px;
  padding-left: 6px;
  font-size: 14px;
  font-weight: normal;
}

.business-panel ul {
  padding: 10px 0px !important;
}

.business-panel ul li {
  list-style: disc !important;
  float: none;
  width: 100%;
  display: block !important;
  margin: 5px 0px;
}

.business-panel p {
  margin: 0px !important;
}

.react-tabs__tab--selected:before,
.react-tabs__tab--selected:after {
  display: none;
}
.react-tabs__tab--selected {
  background: none !important;
  border-color: #aaa;
  color: #006090 !important;
  font-weight: bold;
  border-bottom: 3px #006090 solid !important;
  border-radius: 0 !important;
  margin-bottom: 0px !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-top: 0px !important;
  padding: 15px 30px !important;
}

.react-tabs__tab:hover {
  background: none !important;
  border-color: #aaa;
  color: #006090 !important;
  padding: 15px 30px !important;
  font-weight: bold;
  border-bottom: 3px #006090 solid !important;
  border-radius: 0 !important;
  margin-bottom: 0px !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-top: 0px !important;
}
.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: 0px;
  bottom: -1;
  position: relative;
  list-style: none;
  padding: 15px 30px !important;
  cursor: pointer;
  font-weight: bold;
  color: rgb(0 0 0 / 50%);
}

.react-tabs__tab-panel {
  /* display: none; */
  padding: 20px;
}

/* .navbar-expand-lg .navbar-nav .dropdown-menu {
  position: relative;
} */

a.nav-link.dropdown-toggle {
  text-align: right;
}

.copy-right {
  width: 100%;
  text-align: center;
}
button.btn.btn-primary.go-btn {
  background: #006090;
  border: 0px;
  height: 42px;
}
.accouting-info label {
  margin-bottom: 12px !important;
  font-size: 14px !important;
}

.rdt_TableHeadRow {
  background: #e2eff4 !important;
  font-size: 14px;
  font-weight: bold;
}

.sc-bjfHbI.kNxEtY {
  margin-top: 32px;
  font-weight: bold;
  font-size: 20px;
}
.rdt_TableBody {
  overflow-y: auto;
  padding-right: 0px !important;
  max-height: 400px;

  background-attachment: scroll, scroll, local, local;
  -webkit-overflow-scrolling: touch;
  -overflow-scrolling: touch;
}
.rdt_TableBody::-webkit-scrollbar {
  width: 8px;
  background-color: #ccc;
  border-radius: 20px;
}

.rdt_TableBody::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #999;
  border-radius: 20px;
}

.rdt_TableBody::-webkit-scrollbar-button {
  display: none;
}

.sc-dmctIk.WQNxq {
  overflow-x: auto;
  padding-bottom: 20px !important;

  max-width: 100%;
  background-attachment: local, local, scroll, scroll;
  -webkit-overflow-scrolling: touch;
}
.sc-dmctIk.WQNxq::-webkit-scrollbar {
  height: 8px;
  background-color: #ccc;
  border-radius: 20px;
}

.sc-dmctIk.WQNxq::-webkit-scrollbar-thumb {
  height: 8px;
  background-color: #999;
  border-radius: 20px;
}

.sc-dmctIk.WQNxq::-webkit-scrollbar-button {
  display: none;
}

.rdt_Table {
  width: 100%;
  max-width: 100%;
}

header.sc-dIfARi.kDEcCH {
  margin: 0px 0px 10px 0px !important;
}

button.btn.btn-primary.paid-btn {
  background: #006090;
  border: 0px;
  font-size: 12px;
}

button.btn.btn-primary.paid-btn:hover {
  background: #05537a;
}

.djZVNf {
  width: 50px !important;
  min-width: 50px !important;
}

.sidebar.collapsed p.bottom-text {
  display: none;
}
div#cell-1-undefined {
  min-width: 270px !important;
}

.rdt_TableHeadRow .rdt_TableCol:first-child {
  min-width: 270px !important;
}

.rdt_TableCell {
  line-height: 18px !important;
  font-size: 12px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  color: #000 !important;
}

.rdt_TableRow {
  min-height: 75px !important;
}

.rdt_TableCol {
  font-size: 13px;
  color: #000;
  text-transform: capitalize;
  min-width: 150px !important;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

button:focus {
  outline: 0px;
  outline: 0px !important;
}
button.btn.btn-primary.unreconciled-btn {
  background: #d3cfcf;
  border: 0px;
  color: #000;
  font-size: 12px;
}
button.btn.btn-primary.active-btn {
  font-size: 12px;
  border: 0px;
  background: #006090;
}

button.btn.btn-primary.partially-btn {
  color: #704100;
  background: #eee;
  border: 0px;
  font-size: 12px;
}
button.btn.btn-primary.void-btn {
  font-size: 12px;
  border: 0px;
  background-color: #1fb5ad;
  border-color: #1fb5ad;
  color: #ffffff;
}

button.btn.btn-primary.open-btn {
  font-size: 12px;
  border: 0px;
  background: #006090;
}

button.btn.btn-primary.draft-btn {
  color: #704100;
  background: #eee;
  border: 0px;
  font-size: 12px;
}

.no-data-panel {
  position: absolute;
  width: 200px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-data {
  width: 100%;
  position: relative;
  text-align: center;
  white-space: normal;
  vertical-align: top;
}

.nofound-img {
  width: 30px;
  margin-bottom: 10px;
}

.after-check-status ul.menu {
  position: relative;
  margin-right: 20px;
  padding: 10px 10px 10px 26px;
  background: #fff;
  box-shadow: 0px 1px 1px rgb(0 0 1);
  border-radius: 0px 0px 5px 5px;
  margin-top: -20px;
}
.after-check-status ul.menu li.menu-item {
  line-height: 25px;
}
.after-check-status ul.menu li.menu-item a {
  color: #000;
  cursor: pointer;
}

.after-check-status ul.menu li.menu-item a:hover {
  text-decoration: none;
  color: #006090;
}

.download-panel {
  position: absolute;
  z-index: 9;
  width: 200px;
  right: 0px;
  top: 0;
  margin-top: 135px;
}

button.btn.btn-primary.banking-btn.download-btn {
  margin: 0px 20px 0px 0px;
}

.export-data-div {
  width: 400px;
  float: right;
  background: #fff;
  position: absolute;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  margin: 80px 0 0 0;
  z-index: 9999;
  right: 20px;
}

.export-data-div h3 {
  margin: 0px;
}
.export-data-div p {
  margin: 10px 0;
  display: block;
  width: 100%;
  float: left;
}
.create-new-data {
  background: #006090;
  border: 0px;
  font-size: 12px;
  padding: 10px 20px !important;
}
.create-new-data:hover {
  background: #065278;
}

button#export-assess-codat-data {
  float: right;
}
div#codat-download-cointainer {
  padding: 0px !important;
  border-bottom: 1px #ccc solid;
  padding-bottom: 12px !important;
  margin-bottom: 12px;
}
.btn.btn-primary.refresh-btn {
  margin: 0 auto;
  display: block;
  background: #ccc;
  border: 0px;
  color: #000;
}
a.export-download {
  background: #006090;
  border: 0px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 12px;
}

a.export-download:hover {
  background: #065278;
}

div#codat-download-cointainer p {
  margin: 0px 0px 10px 0px;
}

div#codat-download-cointainer span {
  padding-left: 10px;
}

.export-area {
  position: relative;
  align-self: center;
}
input#upload-file {
  display: none;
}
img.upload-icon {
  margin: 10px auto;
  display: block;
}

label.btn.btn-primary.upload-btn {
  background: #006090;
  border: 0px;
  cursor: pointer;
  padding: 0px 10px;
  margin-bottom: 5px !important;
}

.accouting-info {
  margin-top: 20px;
  float: left;
  width: 100%;
  position: relative;
  min-height: 440px;
}
.rdt_TableRow .rdt_TableCell:first-child {
  min-width: 270px;
}

.input-group-text {
  background-color: #e2e2e2;
}

.fa-pound-sign {
  color: #000000;
}
.sidebar.collapsed li.menu-item a span a {
  padding-left: 14px !important;
  text-align: center;
}
.chart-panel {
  width: 100%;
  margin: 20px 0;
}

.col-for-logo {
  float: right;
  margin-top: 3px;
  width: 182px;
}
.col-for-logo img {
  width: 30px;
  float: left;
}
.col-for-logo h3 {
  float: left;
  margin-left: 10px !important;
  width: calc(100% - 50px);
  vertical-align: top;
  line-height: 16px;
  font-size: 14px;
  margin: 2px 0px 0px 0px;
}
.col-for-logo h3 span {
  display: block;
  font-size: 12px;
}
.box-shape {
  height: 42px;
  background: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  float: left;
  padding: 0px 10px;
  border-radius: 5px;
  line-height: 42px;
  width: 100%;
}
.box-shape label {
  font-size: 13px;
}
input.period-start {
  border: 0px;
  background: 0px;
  float: right;
  width: 100px;
  font-size: 12px;
}
input.period-start:focus,
.box-shape input.period-compare:focus {
  outline: none;
  box-shadow: none;
}
.box-shape select {
  border: 0px;
  float: right;
  margin-top: 8px;
  height: auto !important;
}

.box-shape input.period-compare {
  border-radius: 10px;
  border: 1px #ccc solid;
  padding: 8px;
  width: 62px;
  float: right;
  height: 30px !important;
  margin-top: 5px;
}

.chart-div {
  margin-top: 30px;
}

.col-md-3.source-sandbox {
  margin-top: 10px;
  text-align: center;
}
img.commerce-img {
  width: 24px;
}
.value-box {
  background: #f7fafd;
  width: 150px;
  border-radius: 10px;
  text-align: left;
  padding: 10px;
  float: right;
  margin-left: 12px;
  margin-bottom: 20px;
}

.value-box h2 {
  margin: 0px;
  color: #000;
  font-weight: bold;
  font-size: 18px;
}

.select-graph {
  width: auto;

  float: right;
  clear: both;
  margin: 20px 0px;
}
.select-graph select {
  margin-top: 11px !important;
}

.chart-second {
  width: 100%;
  display: block;
}

.table-data-div table th {
  background: #e8fdfc;
}

.table-data-div .dataTables_wrapper {
  position: relative;
}

.table-data-div {
  margin-top: 20px;
}

.table-data-div table th:first-child {
  font-size: 14px !important;
}

.padding-left-td {
  margin-left: 1rem !important;
  display: inline-block;
  border: 0px !important;
}

.table-data-div table td,
.table-data-div table th {
  min-width: 170px;
  text-align: right;
  vertical-align: middle;
  padding: 26px 12px;
}
.table-data-div table strong {
  color: #000 !important;
  font-size: 12px !important;
}

.table-data-div table th {
  font-size: 12px !important;
}
.table-data-div table td {
  color: #2a2929 !important;
  font-size: 12px !important;
}

.table-data-div table th:first-child,
.table-data-div table td:first-child {
  text-align: left !important;
  min-width: 230px !important;
}
.table-striped.table-bordered th,
.table-striped.table-bordered td {
  text-align: left !important;
  border: 0px !important;
  min-width: 85px !important;
  vertical-align: middle;
}

.table-striped.table-bordered th:first-child {
  min-width: 150px !important;
}
.table-data-div table {
  border: 0px !important;
  margin-bottom: 0px !important;
}
.table-data-div table tr {
  border-bottom: 1px #ccc solid !important;
}
.table-data-div input[type="checkbox"] {
  height: auto !important;
  margin: 0px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: none !important;
}

.rdt_TableCell div:first-child {
  white-space: pre-wrap;
}

table.company_info_table {
  max-width: 70% !important;
}

.table-data-div table {
  border: 0px !important;
  margin-bottom: 0px !important;
}

table#company-table tr td:first-child,
table#commerce_Company-table tr td:first-child {
  background: #d8f5f4;
  font-size: 13px !important;
  font-weight: bold;
  text-transform: capitalize;
}

table#company-table td,
table#commerce_Company-table td {
  background: #f0f4fa;
  border: 5px #fff solid !important;
}

button.btn.btn-primary.inquiryprocessing-btn {
  background: #006090;
  border: 0px;
  font-size: 12px;
}
button.btn.btn-primary.won-btn {
  background: #1fb5ad;
  border: 0px;
  font-size: 12px;
}
button.btn.btn-primary.inquiryevidencerequired-btn {
  background: #006090;
  border: 0px;
  font-size: 12px;
}

button.btn.btn-primary.inquiryprocessing-btn:hover {
  background: #05537a;
}

button.btn.btn-primary.processing-btn {
  font-size: 12px;
  background: #efef6d;
  color: #000;
  border: 0px;
}

button.btn.btn-primary.processing-btn:hover {
  background: #e9e95f;
}

button.btn.btn-primary.accepted-btn {
  background: #72b572;
  border: 0px;
  font-size: 12px;
}

button.btn.btn-primary.accepted-btn:hover {
  background: #6bbd6b;
}

button.btn.btn-primary.inquiryclosed-btn {
  font-size: 12px;
  border: 0px;
}
button.btn.btn-primary.unknown-btn {
  background: #ccc;
  color: #000;
  border: 0px;
  font-size: 12px;
}

.rdt_TableHeader {
  padding: 0px !important;
}

button.btn.btn-primary.authorized-btn {
  font-size: 12px;
  background: #006090;
  color: #fff;
  border: 0px;
}
button.btn.btn-primary.pending-btn {
  background: #ccc;
  color: #000;
  border: 0px;
  font-size: 12px;
}

button.btn.btn-primary.failed-btn {
  background: red;
  border: 0px;
  font-size: 12px;
}

button.btn.btn-primary.cancelled-btn {
  background: red;
  border: 0px;
  font-size: 12px;
}

.table-data-div .padding-left-1 {
  padding-left: 20px !important;
}
.table-data-div .padding-left-2 {
  padding-left: 30px !important;
}

.table-data-div .margin-left-3 {
  margin-left: 40px !important;
}

.chart-panel i.fa.fa-caret-down {
  color: red;
}

.chart-panel i.fa.fa-caret-up {
  color: green;
}

.table-data-div i.fa.fa-chevron-down,
.table-data-div i.fa.fa-chevron-right {
  margin-right: 5px;
}

.data-panel h3 span {
  font-size: 14px;
  color: #006090;
}

button.btn.btn-primary.generate-btn {
  background: #006090;
  border: 0px;
  height: 44px;
  padding: 10px 20px;
}
button.btn.btn-primary.generate-btn:hover {
  background: #07547a;
}
.navbar {
  padding: 0;
  height: 100%;
}

.scroll-bar {
  overflow-y: auto;
  padding-right: 0px !important;
  max-height: 420px;

  background-attachment: scroll, scroll, local, local;
  -webkit-overflow-scrolling: touch;
  -overflow-scrolling: touch;
}
.scroll-bar::-webkit-scrollbar {
  width: 8px;
  background-color: #ccc;
  border-radius: 20px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #999;
  border-radius: 20px;
}

.scroll-bar::-webkit-scrollbar-button {
  display: none;
}

.scroll-bar {
  overflow-x: auto;
  padding-bottom: 20px !important;

  max-width: 100%;
  background-attachment: local, local, scroll, scroll;
  -webkit-overflow-scrolling: touch;
}
.scroll-bar::-webkit-scrollbar {
  height: 8px;
  background-color: #ccc;
  border-radius: 20px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  height: 8px;
  background-color: #999;
  border-radius: 20px;
}

.scroll-bar::-webkit-scrollbar-button {
  display: none;
}

.review-application {
  padding-bottom: 20px;
}

.debtors label {
  display: block;
}

.debtors input {
  width: 100%;
  height: 42px;
  border: 1px #ccc solid;
  border-radius: 5px;
  padding: 0px 10px;
}
.debtors button.btn.btn-primary.generate-btn {
  margin-top: 29px;
}
label.group-report {
  line-height: 40px;
  padding-left: 40px;
  font-size: 12px !important;
}
.debtors select {
  height: 42px;
  width: 100%;
  border: 1px #ccc solid;
  border-radius: 5px;
}

.calender-div.float-left {
  width: max-content;
}

.financial-service .today {
  width: 80px;
  margin: 10px 0px 0 0;
  -webkit-box-shadow: 0px 15px 30px -16px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 15px 30px -16px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 15px 30px -16px rgba(0, 0, 0, 0.3);
}
.financial-service .year {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: uppercase;
  color: white;
  background: #5e99b7;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}

.financial-service .day {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: uppercase;
  color: white;
  background: #5e99b7;
  text-align: center;
  border-radius: 10px 10px 0 0;
}
.financial-service .month {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 200;
  text-align: center;
}
.financial-service .date {
  padding-top: 0;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}
.financial-service .middle {
  border-radius: 0;
}

.scroll-height {
  max-height: 300px !important;
}
.scroll-height {
  max-height: 300px !important;
}

.upload-image label img.upload-img {
  border-radius: 100%;
}
.select-graph select:focus {
  outline: none;
}

.hide {
  display: none !important;
}

input.chkprofitloss {
  margin-right: 5px !important;
}
.table-data-div i.fa.fa-chevron-down,
.table-data-div i.fa.fa-chevron-right {
  margin-right: 5px;
  vertical-align: top;
  margin-top: 2px;
}

.table-data-div td.padding-left-4 {
  padding-left: 70px !important;
}
input.chkbalance {
  margin-right: 5px !important;
}

.hide {
  display: none !important;
}

input.chkprofitloss {
  margin-right: 5px !important;
}
.table-data-div i.fa.fa-chevron-down,
.table-data-div i.fa.fa-chevron-right {
  margin-right: 5px;
  vertical-align: top;
  margin-top: 2px;
}

.table-data-div td.padding-left-4 {
  padding-left: 70px !important;
}
input.chkbalance {
  margin-right: 5px !important;
}

.scroll-bar-2.events-panel {
  max-height: 300px !important;
}

.card.card-debtor {
  box-shadow: 0 1px 2px rgb(111 116 154 / 8%), 0 2px 2px rgb(111 116 154 / 8%),
    0 1px 4px rgb(111 116 154 / 8%);
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  margin-bottom: 40px;
  margin-right: 20px;
}
.col-md-2.debtors label.group-report {
  margin-right: -15px !important;
  float: right;
}

.financial-service.income-panel {
  margin-top: 40px !important;
}
.financial-service {
  margin-top: 40px !important;
}

@media (max-width: 1056px) {
  .scroll-bar-2 {
    height: calc(350px - 48px - 108px);
  }
}

@media (max-width: 1000px) {
  .card-bottom {
    display: block;
  }
  .box-id-1,
  .box-id-2 {
    width: 100%;
  }
}

@media (max-width: 300px) {
  .right-panel-main h3 {
    font-size: 15px !important;
  }

  .banking-col {
    padding: unset;
  }
}
@media (max-width: 476px) {
  .export-dropdown {
    width: calc(100vw - 10vw);
  }
  .react-tabs__tab-panel {
    padding: 7px;
  }
}

@media (max-width: 1200px) {
  button.btn.btn-primary.exportdata-btn.next-btn {
    float: left;
  }
  .col-for-logo {
    float: left;
    margin: 10px 0 10px 0;
  }
}

@media (max-width: 454px) {
  .company-name {
    font-size: 14px;
  }
}

.dropdown-item {
  padding: 5px;
}

@media screen and (max-width: 1056px) and (min-width: 1000px) {
  .income-panel .box-id-1,
  .financial-service .box-id-1 {
    width: 100% !important;
  }
  .income-panel .box-id-2,
  .financial-service .box-id-2 {
    width: 100%;
  }

  /* .regular-outgoings .box-id-2 {
    width: 60%;
  } */
}

@media screen and (max-width: 853px) and (min-width: 768px) {
  .income-panel .box-id-1,
  .financial-service .box-id-1 {
    width: 100% !important;
  }
  .income-panel .box-id-2,
  .financial-service .box-id-2 {
    width: 100%;
  }
  .regular-outgoings .box-id-2 {
    width: 60%;
  }

  .regular-outgoings .card-bottom .box-id-2 {
    width: 100%;
  }
}

@media screen and (max-width: 382px) and (min-width: 0px) {
  .box-id-1 {
    width: 100% !important;
  }
  .box-id-2 {
    width: 100%;
  }
  .regular-outgoings .box-id-2 {
    width: 60%;
  }

  .regular-outgoings .card-bottom .box-id-2 {
    width: 100%;
  }
}

@media screen and (max-width: 315px) and (min-width: 0px) {
  .regular-outgoings .box-id-2 {
    width: 100%;
  }
}

@media screen and (max-width: 621px) and (min-width: 576px) {
  .nav-item.business-btn {
    padding: unset;
  }
}

@media (max-width: 325px) {
  .dashboard-panel .navbar-dark .navbar-brand {
    width: calc(100% - 130px) !important;
  }
}
/* @media (max-width: 1060px) {
  .financial-service .scroll-bar-2 {
    max-height: 193px ;
  }
} */

@media (max-width: 576px) {
  .initial-avatar {
    margin-right: unset !important;
  }

  /* .financial-service .scroll-bar-2 {
    max-height: 205px;
  } */
  .dropdown-menu {
    left: -70px;
  }
  .react-tabs__tab {
    font-size: 13px;
  }
  .banking-url label {
    font-size: 13px;
  }
  .dashboard-panel .navbar-dark .navbar-brand {
    width: calc(100% - 153px);
  }
  .sidebar-stickybox {
    display: none;
  }
  /* .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  } */

  .upload-doc-panel {
    padding: 10px;
  }
}

.react-tabs__tab-list {
  display: flex;
}

@media (max-width: 400px) {
  .navbar-dark .navbar-toggler-icon {
    height: 1em;
    width: 1em;
  }
  .navbar-toggler {
    padding: 5px;
  }
  .dropdown-menu {
    font-size: 13px;
    margin: 5px;
  }
}

@media (max-width: 430px) {
  .merchant-body {
    padding: unset;
  }
}

@media (max-width: 576px) {
  #responsive-navbar-nav {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  span.logo-icon {
    left: unset;
  }
  .company-name {
    margin-left: 5px;
    width: 60%;
  }
  .navbar-toggler {
    margin-right: 10px;
  }
  .header-dropdown {
    justify-content: flex-start !important;
    padding-left: 10px !important;
  }
}

@media (max-width: 350px) {
  .checkstatus-btn {
    padding: 10.5px 5px !important;
  }
}

@media (max-width: 980px) {
  .login-box {
    width: 92%;
    margin: 12px auto;
  }

  .right-panel {
    width: 96%;
    padding: 0px 20px;
    position: relative;
    left: 0;
    top: 0;
    float: left;
    transform: translate(0%);
  }

  .copyright {
    width: 100%;
    float: left;
    text-align: center;
    /* position: relative; */
    right: 0;
    bottom: 0px;
  }

  .right-panel {
    width: 96%;
    padding: 0px 20px;
    position: relative;
    left: 0;
    top: 0;
    float: left;
    transform: translate(0%);
  }

  .export-data-div {
    width: 100%;
    float: right;
    background: #fff;
    position: absolute;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    margin: 0px 0 0 0;
    z-index: 9999;
    right: 0;
  }

  button#export-codat-data {
    margin: 10px auto;
    display: block;
  }
  button#export-assess-codat-data {
    display: block;
    float: none;
    margin: 0 auto;
  }
  div#codat-download-cointainer .col-md-8 {
    width: calc(100% - 80px) !important;
    float: left;
    padding-right: 0px !important;
  }
  div#codat-download-cointainer .col-md-4 {
    width: 80px !important;
    float: right;
    padding: 0px !important;
  }
  div#codat-download-cointainer .col-md-8 p {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .right-panel-main h3 {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }
  .react-tabs__tab {
    width: auto;
    text-align: center;
    padding: 10px !important;
  }
  .download-panel {
    position: relative;
    z-index: 9;
    width: 100%;
    right: 0px;
    top: 0;
    display: block;
    margin-top: 0;
    float: left;
  }
  .download-btn {
    position: relative;
    right: 0px;
    top: 0;
  }
  select.form-control {
    height: 42px !important;
    margin-bottom: 12px;
  }
  input.form-control {
    height: 45px !important;
    margin-bottom: 12px;
  }
  button.btn.btn-primary.exportdata-btn.next-btn {
    margin: 12px 00px 0px 0px !important;
    height: 42px;
    float: left;
  }
  button.copyicon-col.btn.btn-primary {
    background: #006090;
    border: 0px;
    padding: 10.5px 20px;
    position: absolute;
    right: 0px;
    margin-top: -57px;
    border-radius: 0px 5px 5px 0px;
  }
  button.checkstatus-btn.btn.btn-primary {
    background: #006090;
    border: 0px;
    padding: 10.5px 20px;
    position: absolute;
    right: 0px;
    margin-top: -57px;
    border-radius: 0px 5px 5px 0px;
  }
  .financial-service {
    border: 1px #5e99b7 solid;
    border-radius: 5px;
    margin: 10px 0px 0px 0px;
    position: relative;
    float: left;
  }

  /* .navbar-dark .navbar-toggler {
    display: none;
  } */

  div#navbarScroll {
    display: block;
  }
  div#navbarScroll {
    display: block;
    width: auto;
    max-width: 100px;
  }
  /* .dashboard-panel .navbar-dark .navbar-brand {
    width: auto;
  } */
  .navbar-dark .navbar-brand strong {
    padding-left: 40px;
  }
  .dashboard-panel .navbar-dark .navbar-brand {
    /* width: auto; */
    font-size: 15px;
  }
  li.nav-item.business-btn.cursor-pointer {
    display: none;
  }

  .col-md-8.request-right {
    position: relative !important;
    right: auto !important;
  }
  .col-md-4.left-panel-box {
    position: relative !important;
  }
  .left-panel {
    height: 650px;
  }

  /* .box-id-2 {
    width: 45%;
    float: right;
    margin-left: 0;
    margin-top: 5px;
  } */

  .box-id-1 {
    /* width: 50%; */
    float: left;
    margin-left: 0;
    margin-top: 5px;
    text-align: right;
  }

  .box-id-1 p,
  .box-id-2 p {
    text-align: right;
  }
  .financial-service .scroll-bar-2 {
    /* max-height: 205px; */
  }
  .scroll-bar-2.events-panel {
    max-height: 300px !important;
  }
  .after-check-status ul.menu {
    position: relative;
    margin-right: 20px;
    padding: 10px 10px 10px 26px;
    background: #fff;
    box-shadow: 0px 1px 1px rgb(0 0 1);
    border-radius: 0px 0px 5px 5px;
    margin-top: 45px;
    max-width: 200px;
  }
  .bottom-2 {
    background: #e2e2e2;
    min-height: 92px;
  }
  /* .card-bottom {
    min-height: 92px;
  } */
  .react-tabs__tab:hover,
  .react-tabs__tab--selected {
    padding: 10px !important;
  }

  .no-data-panel h3 {
    text-align: center;
    margin: 0 0px 10px 0px;
  }

  div#RFS-StepperContainer {
    overflow: auto;
    padding: 100px 0px 20px 0px !important;
  }

  .verify-panel .dashboard-box.position-relative.card.dashboard-card {
    width: auto !important;
    max-width: calc(100% - 100px);
    float: right;
    margin-top: 80px;
    margin-right: 0px !important;
  }

  .verify-panel.dashboard-panel-div
    .dashboard-box.position-relative.card.dashboard-card {
    width: 100% !important;
    float: left !important;
    max-width: 100% !important;
    margin-top: 20px !important;
  }
}
.no-data-panel h3 {
  text-align: center;
  margin: 0 0px 10px 0px;
}

.initial-avatar {
  /* Center the content */
  display: inline-block;
  margin-right: 10px;
  position: relative;

  /* Colors */
  background-color: #198754;

  /* Rounded border */
  border-radius: 50%;
  height: 35px;
  width: 35px;
}
.initial-avatar-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat";
}

.header-dropdown {
  align-items: center;
  display: flex;
  width: 180px;
  justify-content: flex-end;
  color: #ffffff;
}
.navbar-nav .dropdown-menu {
  right: 0;
}

#responsive-navbar-nav {
  background-color: #006090;
  /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
}

.resp-nav {
  background-color: #196f9b;
  margin-top: -3px;
}
.dropdown-nav ul {
  margin-bottom: unset;
}

.dropdown-nav ul a {
  padding: 5px 7px;
}

.profile-pic {
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.col-md-4.left-panel-box {
  position: relative !important;
}
/* .left-panel {
  height: 650px;
} */

div#RFS-StepperContainer {
  overflow: auto;
  padding: 100px 0px 20px 0px !important;
}
div#RFS-StepMain {
  display: block !important;
  text-align: center;
  margin: 0 auto;
  float: none;
  width: 100px;
}
button#RFS-StepButton {
  margin: 0 auto;
}

.verify-panel .dashboard-box.position-relative.card.dashboard-card {
  width: auto !important;
  /* max-width: calc(100% - 100px); */
  /* float: right; */
  margin-top: 80px;
  margin-right: 0px !important;
}

div#RFS-StepContainer {
  padding: 0px !important;
}

.verify-panel {
  margin: 0px 0px 20px 0;
  text-align: left;
  width: 100%;
  float: left;
}

.StepperContainer-0-2-1 {
  padding-top: 80px !important;
}

div#RFS-StepperContainer {
  overflow: auto;
  padding: 100px 0px 20px 0px !important;
}

.proof-btns {
  background: #006090;
  border: 0px;
  cursor: pointer;
  /* padding: 0px 10px; */
}

div#RFS-StepMain {
  display: block !important;
  text-align: center;
  margin: 0 auto;
  float: none;
  width: 100px;
}
button#RFS-StepButton {
  margin: 0 auto;
}

#RFS-Label {
  font-family: Arial, Helvetica, sans-serif !important;
  /* font-size: 12px !important; */
  margin-top: 4px;
  margin-bottom: 10px;
}

.verify-panel {
  margin: 0px 0px 20px 0;
  text-align: left;
  width: 100%;
  float: left;
}

.StepperContainer-0-2-1 {
  padding-top: 80px !important;
}

.company-name {
  white-space: nowrap;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-left: 50px; */
}

.file-name {
  min-width: 250px;
}

.credit-score-table-head th {
  background-color: #006090;
  color: white;
  /* border: 5px #fff solid !important; */
  padding: 0.75rem;
}

.credit-score-table td {
  width: 40%;
  padding: 10px;
}

.download-score-btn,
.download-score-btn:hover {
  background-color: #006090;
  color: white;
  float: right;
  margin: 0 0 20px 0;
}

._loading_overlay_content.css-42igfv {
  top: 50%;
  position: fixed;
  left: 50%;
  width: 50px;
  z-index: 99999;
  height: 50px;
  opacity: 1;
  background: none !important;
  transform: translate(-50%, -50%);
  display: flex;
}

.color-primary {
  color: #006090;
}

.bg-app-primary {
  background-color: #006090;
}

.center {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.dashboard-body.bg-change-color {
  --page-width-minus-scroll: calc(100vw - (100vw - 100%));
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
  min-width: var(--content-min-width);
}

.container-fluid.merchant-body {
  max-width: var(--content-with-sidebar-max-width);
  margin: 0 auto;
}
.dropdown-toggle {
  cursor: pointer;
}
:root {
  --modal-border-radius: 10px;
  --header-height: 96px;
  --width: 96px;
  --content-min-width: calc(var(--sidebar-width) - 100vw);
  --content-max-width: 1216px;
  --sidebar-width: 238px;
  --sidebar-margin-right: 2rem;
  --content-with-sidebar-max-width: calc(
    var(--content-max-width) + var(--sidebar-width) +
      var(--sidebar-margin-right)
  );
  --content-with-sidebar-min-width: calc(
    var(--content-min-width) + var(--sidebar-width) +
      var(--sidebar-margin-right)
  );
}

@media (min-width: 1440px) {
  .sidebar {
    max-height: 812px;
  }

  .box-shape {
    height: 42px;
    background: #fff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
    float: left;
    padding: 0px 5px;
    border-radius: 5px;
    line-height: 42px;
    width: 100%;
  }
  .accouting-info label {
    margin-bottom: 12px !important;
    font-size: 12px !important;
  }

  .box-shape select {
    border: 0px;
    float: right;
    width: 80px;
    margin-top: 13px;
    height: auto !important;
  }

  .box-shape input.period-compare {
    border-radius: 10px;
    border: 1px #ccc solid;
    padding: 8px;
    width: 51px;
    float: right;
    height: 30px !important;
    margin-top: 5px;
  }
  input.period-start {
    border: 0px;
    background: 0px;
    float: right;
    width: 90px;
    font-size: 12px;
  }
}

@media (max-width: 1280px) {
  .disclaimer {
    width: unset !important;
  }
}

@media (max-width: 992px) {
  /* .navbar {
    padding: 0.5rem 1rem;
    height: 100%;
  } */

  /* .border-divider{
    display: ;
  } */
}

.mySelect__value-container {
  height: 43px;
}

.sidebar-panel .sub-menu a.menu-anchor {
  padding-right: 20px;
  padding-left: 15px !important;
  margin-bottom: 5px;
  background-color: unset;
  /* line-height:35px;
  height:35px; */
}

.sidebar-panel li.menu-item.submenu,
.sidebar-panel li.menu-item a span a,
.sidebar-panel .sub-menu a.menu-anchor {
  height: 40px;
  line-height: 40px;
}

.sidebar-panel .sub-menu .ejzYLa {
  background-color: unset !important;
  padding-right: 0 !important;
}

.sub-menu-content {
  line-height: 45px;
}

.view-files table th,
.upload-files table th {
  border-bottom-width: 1px !important ;
}

.sidebar-panel .submenu-item a.menu-item .menu-anchor:hover {
  background-color: #e2eff4 !important;
}
#root
  > div
  > div.dashboard-panel
  > div
  > div
  > div
  > div:nth-child(1)
  > div
  > aside
  > div
  > nav
  > ul
  > li.sub-menu.menu-item.open.submenu-item
  > div
  > ul
  > li:nth-child(1)
  > a:hover,
#root
  > div
  > div.dashboard-panel
  > div
  > div
  > div
  > div:nth-child(1)
  > div
  > aside
  > div
  > nav
  > ul
  > li.sub-menu.menu-item.open.submenu-item
  > div
  > ul
  > li:nth-child(2)
  > a:hover {
  background-color: unset !important;
}

#root
  > div
  > div.dashboard-panel
  > div
  > div
  > div
  > div:nth-child(1)
  > div
  > aside
  > div
  > nav
  > ul
  > li.sub-menu.menu-item.open.submenu-item
  > div
  > ul
  > li.menu-item.active.submenu
  > a {
  background-color: unset !important;
}

.sub-menu-content {
  background-color: transparent !important;
}

.expand-icon span {
  height: 10px;
  width: 10px;
}

.sidebar-panel .sub-menu {
  margin-top: 5px;
}

.sidebar-panel .sub-menu .menu-icon {
  margin-right: 0 !important;
}

.sidebar-panel .sub-menu .menu-label a {
  margin-left: 35px !important;
}

.view-files th {
  position: sticky;
}

.sidebar-panel .eVPmrC {
  padding-right: 0 !important;
}

.file-icon,
.empty-text {
  color: lightgray;
}

.file-icon {
  font-size: 25px;
}
.empty-text {
  font-size: 20px;
  font-weight: 600;
}

.sidebar-panel .sub-menu-content {
  background-color: rgba(249, 249, 249, 0.7);
}

.text-disabled,
.text-disabled > strong,
.text-disabled > div {
  color: #b3afaf;
  font-weight: unset;
}

.text-gray {
  color: #687473;
  width: 85px;
  font-weight: 700;
}

.Accounting-left-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 382px) {
  .financial-service .scroll-bar-2 {
    height: 192px !important;
  }
  .box-id-1,
  .box-id-2 {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .upload-doc-panel:before {
    background-color: unset;
  }

  /* .financial-service .scroll-bar-2 {
    max-height: 230px;
  } */

  .box-id-1,
  .box-id-2 {
    width: 50%;
  }

  .financial-service .scroll-bar-2 {
    height: 237px;
  }
}

@media (max-width: 576px) {
  .financial-service .scroll-bar-2 {
    height: 237px;
  }
}

.ellipsis-text {
  white-space: nowrap !important;
  /* width: 85% !important; */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

#dropdown-basic-user {
  color: #ffffff;
  display: flex;
  align-items: center;
}

#dropdown-basic-user {
  text-decoration: none;
}

.dropdown-nav .dropdown-item {
  padding: 5px;
}

@media screen and (min-width: 576px) and (max-width: 604px) {
  .nav-item.business-btn {
    padding: unset;
  }
}

.icon-div {
  width: 27px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.rotate-arrow {
  transform: rotate(-90deg);
  transition: all 0.5s linear;
}

.transition {
  transition: all 0.5s linear;
}

.my-business-label {
  margin-left: 27px;
  font-weight: 600;
  font-size: 17px;
}

input[type="date"] {
  text-transform: uppercase;
}

#country-select.is-invalid #rfs-btn {
  border-color: #dc3545;
}

.country-name {
  font-weight: bold;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: unset !important;
}

.tab-margin {
  margin-right: 4 0px;
}

.option-btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.user-table .rdt_TableBody {
  height: 500px;
}

.user-table .dropdown-item {
  padding: 10px 5px;
  cursor: pointer;
}

.user-table .dropdown-item:hover {
  color: #16181b;
  background-color: #f8f9fa;
}

.badge-circle {
  background-color: red;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.sub-text {
  color: #606060;
  font-weight: 500;
}

.duration-text {
  color: #bababa;
  font-size: 13px;
}

.insights-count {
  font-size: 13px;
  color: #404040;
  font-weight: 500;
}

.sub-title {
  color: #606060;
  font-weight: 500;
  font-size: 14px;
}
.insight-count {
  color: #606060;
  font-weight: 500;
  font-size: 14px;
}

.dashboard-icons {
  color: #606060;
}

.btn-outline-primary {
  border-color: #006090;
  color: #006090;
}

.btn-outline-primary:hover {
  background-color: #006090;
  color: #ffffff;
  border-color: #006090;
}

.disable {
  pointer-events: none;
}

.btn-outline-report {
  border-color: #02547a !important;
color: #02547a !important;

}

.btn-outline-report:hover {
  background-color: #02547a !important;
  color: #ffffff !important;

}

.btn-report-modal {
  background-color: #02547a !important;
  color: #ffffff !important;

}

.btn-report-modal:hover,.btn-report-modal:active {
  background-color: #02547a !important;
  color: #ffffff !important;

}

.merchant-dashaboard .card{
  height: 350px;
  overflow-y: auto;
}

.merchant-dashboard-link{
  padding:unset !important;
  margin-bottom: 10px;
}

.insights-updates{
  height: 60px;
  overflow-y: auto;
}

