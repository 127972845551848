/* .App {
  text-align: center;
} */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stepper.completed {
  background-color: #198754 !important;
}

#RFS-Label {
  color: #646464;
  /* font-weight: 600; */
  font-size: 16px;
}

.stepper.active {
  background-color: #006090 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.StepperContainer-0-2-1 {
  padding-top: 100px !important;
}

.tooltip-inner {
  background: white;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 14%);
  border-radius: 4px;
  color: black;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  max-width: unset;
}

#button-tooltip-link-to-banking {
  margin-left: 5px;
  max-width: 400px;
  text-align: left;
}

.tooltip.show {
  opacity: 1;
}

.banking-info-tooltip {
  margin-top: 20px;
  color: gray;
}

.proof-doc-btns {
  background: #006090;
  border: 0px;
}

.proof-doc-btns:hover {
  background: #006090;
}
.initial-avatar {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  background-color: #198754;
  border-radius: 50%;
  height: 35px;
  width: 35px;
}

.initial-avatar-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat;
}

.header-dropdown {
  align-items: center;
  display: flex;
  width: 180px;
  justify-content: flex-end;
}

.initials-profile-pic {
  position: relative;

  /* Colors */
  background-color: #198754;
  color: #fff;
  border-color: white;

  /* Rounded border */
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

.initials-profile-pic-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 40px;
  line-height: 49px;
}

.bg-active {
  background-color: #005479;
  font-weight: 600;
  font-size: 14px;
}


.form-group {
    margin-bottom: 1rem !important;
}